<template lang="pug">
include ../../../configs/template
div.mx-5
  div.row
    div.col-sm-12.col-md-6.text-left
      +data-info('fullNameBefore', 'oldFullName')
    div.col-sm-12.col-md-6.text-left
      +data-info('fullNameAfter', 'newFullName')
    div.col-sm-12.col-md-6.text-left
      +data-info('fullNameBeforeEng', 'oldFullNameEng')
    div.col-sm-12.col-md-6.text-left
      +data-info('fullNameAfterEng', 'newFullNameEng')
    div.col-sm-12.col-md-6.text-left
      +data-info('dateModified', 'getDateFormat(sailorDocument.change_date)')
    div.col-sm-12.col-md-6.text-left
      +data-info('createDate', 'getDateFormat(sailorDocument.date_create)')
</template>

<script>
import { getDateFormat } from '@/mixins/main'
import { mapState } from 'vuex'

export default {
  name: 'SailorFullNameChangesInfo',
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getDateFormat
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    oldFullName () {
      return `${this.sailorDocument[`old_last_${this.nameLang}`]} ${this.sailorDocument[`old_first_${this.nameLang}`]} ${this.sailorDocument[`old_middle_${this.nameLang}`]}`
    },
    newFullName () {
      return `${this.sailorDocument[`last_${this.nameLang}`]} ${this.sailorDocument[`first_${this.nameLang}`]} ${this.sailorDocument[`middle_${this.nameLang}`] || ''}`
    },
    oldFullNameEng () {
      return `${this.sailorDocument.old_last_name_eng} ${this.sailorDocument.old_first_name_eng} ${this.sailorDocument?.old_middle_name_eng || ''}`
    },
    newFullNameEng () {
      return `${this.sailorDocument.last_name_eng} ${this.sailorDocument.first_name_eng} ${this.sailorDocument?.middle_name_eng || ''}`
    }
  }
}
</script>
